import { useBoolean } from "@fluentui/react-hooks";
import { userContext } from "UserContext";
import { useNewPortalBackendEndpoint } from "Utils/EndpointUtils";
import * as React from "react";
import ConnectImage from "../../../../images/HdeConnectCosmosDB.svg";
import ErrorImage from "../../../../images/error.svg";
import { AuthType } from "../../../AuthType";
import { BackendApi, HttpHeaders } from "../../../Common/Constants";
import { configContext } from "../../../ConfigContext";
import { isResourceTokenConnectionString } from "../Helpers/ResourceTokenUtils";
export const fetchEncryptedToken = async (connectionString) => {
    if (!useNewPortalBackendEndpoint(BackendApi.GenerateToken)) {
        return await fetchEncryptedToken_ToBeDeprecated(connectionString);
    }
    const headers = new Headers();
    headers.append(HttpHeaders.connectionString, connectionString);
    const url = configContext.PORTAL_BACKEND_ENDPOINT + "/api/connectionstring/token/generatetoken";
    const response = await fetch(url, { headers, method: "POST" });
    if (!response.ok) {
        throw response;
    }
    const encryptedTokenResponse = await response.json();
    return decodeURIComponent(encryptedTokenResponse);
};
export const fetchEncryptedToken_ToBeDeprecated = async (connectionString) => {
    const headers = new Headers();
    headers.append(HttpHeaders.connectionString, connectionString);
    const url = configContext.BACKEND_ENDPOINT + "/api/guest/tokens/generateToken";
    const response = await fetch(url, { headers, method: "POST" });
    if (!response.ok) {
        throw response;
    }
    // This API has a quirk where it must be parsed twice
    const result = JSON.parse(await response.json());
    return decodeURIComponent(result.readWrite || result.read);
};
export const isAccountRestrictedForConnectionStringLogin = async (connectionString) => {
    const headers = new Headers();
    headers.append(HttpHeaders.connectionString, connectionString);
    const backendEndpoint = useNewPortalBackendEndpoint(BackendApi.AccountRestrictions)
        ? configContext.PORTAL_BACKEND_ENDPOINT
        : configContext.BACKEND_ENDPOINT;
    const url = backendEndpoint + "/api/guest/accountrestrictions/checkconnectionstringlogin";
    const response = await fetch(url, { headers, method: "POST" });
    if (!response.ok) {
        throw response;
    }
    return (await response.text()).toLowerCase() === "true";
};
export const ConnectExplorer = ({ setEncryptedToken, login, setAuthType, connectionString, setConnectionString, }) => {
    const [isFormVisible, { setTrue: showForm }] = useBoolean(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const enableConnectionStringLogin = !userContext.features.disableConnectionStringLogin;
    return (React.createElement("div", { id: "connectExplorer", className: "connectExplorerContainer", style: { display: "flex" } },
        React.createElement("div", { className: "connectExplorerFormContainer" },
            React.createElement("div", { className: "connectExplorer" },
                React.createElement("p", { className: "connectExplorerContent" },
                    React.createElement("img", { src: ConnectImage, alt: "Azure Cosmos DB" })),
                React.createElement("p", { className: "welcomeText" }, "Welcome to Azure Cosmos DB"),
                isFormVisible && enableConnectionStringLogin ? (React.createElement("form", { id: "connectWithConnectionString", onSubmit: async (event) => {
                        event.preventDefault();
                        setErrorMessage("");
                        if (await isAccountRestrictedForConnectionStringLogin(connectionString)) {
                            setErrorMessage("This account has been blocked from connection-string login. Please go to cosmos.azure.com/aad for AAD based login.");
                            return;
                        }
                        if (isResourceTokenConnectionString(connectionString)) {
                            setAuthType(AuthType.ResourceToken);
                            return;
                        }
                        const encryptedToken = await fetchEncryptedToken(connectionString);
                        setEncryptedToken(encryptedToken);
                        setAuthType(AuthType.ConnectionString);
                    } },
                    React.createElement("p", { className: "connectExplorerContent connectStringText" }, "Connect to your account with connection string"),
                    React.createElement("p", { className: "connectExplorerContent" },
                        React.createElement("input", { className: "inputToken", type: "text", required: true, placeholder: "Please enter a connection string", value: connectionString, onChange: (event) => {
                                setConnectionString(event.target.value);
                            } }),
                        errorMessage.length > 0 && (React.createElement("span", { className: "errorDetailsInfoTooltip" },
                            React.createElement("img", { className: "errorImg", src: ErrorImage, alt: "Error notification" }),
                            React.createElement("span", { className: "errorDetails" }, errorMessage)))),
                    React.createElement("p", { className: "connectExplorerContent" },
                        React.createElement("input", { className: "filterbtnstyle", type: "submit", value: "Connect" })),
                    React.createElement("p", { className: "switchConnectTypeText", onClick: login }, "Sign In with Azure Account"))) : (React.createElement("div", { id: "connectWithAad" },
                    React.createElement("input", { className: "filterbtnstyle", type: "button", value: "Sign In", onClick: login }),
                    enableConnectionStringLogin && (React.createElement("p", { className: "switchConnectTypeText", "data-test": "Link:SwitchConnectionType", onClick: showForm }, "Connect to your account with connection string"))))))));
};
