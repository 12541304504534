export function parseResourceTokenConnectionString(connectionString) {
    let accountEndpoint;
    let collectionId;
    let databaseId;
    let partitionKey;
    let resourceToken;
    const connectionStringParts = connectionString.split(";");
    connectionStringParts.forEach((part) => {
        if (part.startsWith("type=resource")) {
            resourceToken = part + ";";
        }
        else if (part.startsWith("AccountEndpoint=")) {
            accountEndpoint = part.substring(16);
        }
        else if (part.startsWith("DatabaseId=")) {
            databaseId = part.substring(11);
        }
        else if (part.startsWith("CollectionId=")) {
            collectionId = part.substring(13);
        }
        else if (part.startsWith("PartitionKey=")) {
            partitionKey = part.substring(13);
        }
        else if (part !== "") {
            resourceToken += part + ";";
        }
    });
    if (resourceToken && resourceToken.endsWith(";")) {
        resourceToken = resourceToken.substring(0, resourceToken.length - 1);
    }
    return {
        accountEndpoint,
        collectionId,
        databaseId,
        partitionKey,
        resourceToken,
    };
}
export function isResourceTokenConnectionString(connectionString) {
    return !!connectionString && connectionString.includes("type=resource");
}
