export function extractFeatures(given = new URLSearchParams(window.location.search)) {
    var _a;
    const downcased = new URLSearchParams();
    const set = (value, key) => {
        downcased.set(key.toLowerCase(), value);
    };
    const get = (key, defaultValue) => { var _a, _b; return (_b = (_a = downcased.get("feature." + key)) !== null && _a !== void 0 ? _a : downcased.get(key)) !== null && _b !== void 0 ? _b : defaultValue; };
    try {
        new URLSearchParams(window.parent.location.search).forEach(set);
    }
    catch (_b) {
        //
    }
    finally {
        given.forEach(set);
    }
    return {
        canExceedMaximumValue: "true" === get("canexceedmaximumvalue"),
        cosmosdb: "true" === get("cosmosdb"),
        enableAadDataPlane: "true" === get("enableaaddataplane"),
        enableResourceGraph: "true" === get("enableresourcegraph"),
        enableChangeFeedPolicy: "true" === get("enablechangefeedpolicy"),
        enableFixedCollectionWithSharedThroughput: "true" === get("enablefixedcollectionwithsharedthroughput"),
        enableKOPanel: "true" === get("enablekopanel"),
        enableNotebooks: "true" === get("enablenotebooks"),
        enableReactPane: "true" === get("enablereactpane"),
        enableRightPanelV2: "true" === get("enablerightpanelv2"),
        enableSchema: "true" === get("enableschema"),
        enableSDKoperations: "true" === get("enablesdkoperations"),
        enableSpark: "true" === get("enablespark"),
        enableTtl: "true" === get("enablettl"),
        enableKoResourceTree: "true" === get("enablekoresourcetree"),
        executeSproc: "true" === get("dataexplorerexecutesproc"),
        hostedDataExplorer: "true" === get("hosteddataexplorerenabled"),
        mongoProxyEndpoint: get("mongoproxyendpoint"),
        mongoProxyAPIs: get("mongoproxyapis"),
        junoEndpoint: get("junoendpoint"),
        phoenixEndpoint: get("phoenixendpoint"),
        notebookBasePath: get("notebookbasepath"),
        notebookServerToken: get("notebookservertoken"),
        notebookServerUrl: get("notebookserverurl"),
        sandboxNotebookOutputs: "true" === get("sandboxnotebookoutputs", "true"),
        selfServeType: get("selfservetype"),
        pr: get("pr"),
        showMinRUSurvey: "true" === get("showminrusurvey"),
        ttl90Days: "true" === get("ttl90days"),
        autoscaleDefault: "true" === get("autoscaledefault"),
        partitionKeyDefault: "true" === get("partitionkeytest"),
        partitionKeyDefault2: "true" === get("pkpartitionkeytest"),
        notebooksDownBanner: "true" === get("notebooksDownBanner"),
        enableThroughputCap: "true" === get("enablethroughputcap"),
        enableHierarchicalKeys: "true" === get("enablehierarchicalkeys"),
        enableCopilot: "true" === get("enablecopilot", "true"),
        copilotVersion: (_a = get("copilotversion")) !== null && _a !== void 0 ? _a : "v2.0",
        disableCopilotPhoenixGateaway: "true" === get("disablecopilotphoenixgateaway"),
        enableCopilotFullSchema: "true" === get("enablecopilotfullschema", "true"),
        copilotChatFixedMonacoEditorHeight: "true" === get("copilotchatfixedmonacoeditorheight"),
        enablePriorityBasedExecution: "true" === get("enableprioritybasedexecution"),
        disableConnectionStringLogin: "true" === get("disableconnectionstringlogin"),
        enableDocumentsTableColumnSelection: "true" === get("enabledocumentstablecolumnselection"),
    };
}
export function hasFlag(flags, desiredFlag) {
    if (!flags || !desiredFlag) {
        return false;
    }
    const features = flags.split("|");
    return features.find((feature) => feature === desiredFlag) ? true : false;
}
